import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en.json";
import esTranslations from "./locales/es.json";
import frTranslations from "./locales/fr.json";
import enSearch from "./locales/features/search/en.json";
import esSearch from "./locales/features/search/es.json";
import frSearch from "./locales/features/search/fr.json";
import frPasswordGenerator from "./locales/features/password-generator/fr.json";
import enLoremIpsum from "./locales/features/lorem-ipsum/en.json";
import esLoremIpsum from "./locales/features/lorem-ipsum/es.json";
import frLoremIpsum from "./locales/features/lorem-ipsum/fr.json";
import enColorPicker from "./locales/features/color-picker/en.json";
import enBarcodeGenerator from "./locales/features/barcode-generator/en.json";
import esBarcodeGenerator from "./locales/features/barcode-generator/es.json";
import frBarcodeGenerator from "./locales/features/barcode-generator/fr.json";
import enJsonFormatter from "./locales/features/json-formatter/en.json";
import esJsonFormatter from "./locales/features/json-formatter/es.json";
import frJsonFormatter from "./locales/features/json-formatter/fr.json";
import enPrivacy from "./locales/privacy/en.json";
import enTerms from "./locales/terms/en.json";
import enContact from "./locales/contact/en.json";
import enUnitExplanations from "./locales/units/en.json";
import esUnitExplanations from "./locales/units/es.json";
import frUnitExplanations from "./locales/units/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...enTranslations,
        search: enSearch,
        units: enUnitExplanations,
        privacy: enPrivacy,
        terms: enTerms,
        contact: enContact,
        barcodeGenerator: enBarcodeGenerator,
        jsonFormatter: enJsonFormatter,
        tools: {
          ...enTranslations.tools,
          loremIpsumGenerator: enLoremIpsum,
          colorPicker: enColorPicker,
          barcodeGenerator: enBarcodeGenerator,
          jsonFormatter: enJsonFormatter,
        },
      },
    },
    es: {
      translation: {
        ...esTranslations,
        search: esSearch,
        units: esUnitExplanations,
        barcodeGenerator: esBarcodeGenerator,
        jsonFormatter: esJsonFormatter,
        tools: {
          ...esTranslations.tools,
          loremIpsumGenerator: esLoremIpsum,
        },
      },
    },
    fr: {
      translation: {
        ...frTranslations,
        search: frSearch,
        barcodeGenerator: frBarcodeGenerator,
        jsonFormatter: frJsonFormatter,
        tools: {
          ...frTranslations.tools,
          passwordGenerator: frPasswordGenerator,
          loremIpsumGenerator: frLoremIpsum,
        },
        units: frUnitExplanations,
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
