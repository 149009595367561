import { Routes as RouterRoutes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import { Skeleton } from "@/components/ui/skeleton";

// Lazy load all pages
const Index = lazy(() => import(/* webpackPrefetch: true */ "@/pages/Index"));
const About = lazy(() => import("@/pages/About"));
const Privacy = lazy(() => import("@/pages/Privacy"));
const Terms = lazy(() => import("@/pages/Terms"));
const Contact = lazy(() => import("@/pages/Contact"));
const AllTools = lazy(() => import("@/pages/tools/AllTools"));
const ColorPicker = lazy(() => import("@/pages/tools/ColorPicker"));
const LoremIpsumGenerator = lazy(
  () => import("@/pages/tools/LoremIpsumGenerator")
);
const PasswordGenerator = lazy(() => import("@/pages/tools/PasswordGenerator"));
const QRGenerator = lazy(() =>
  import("@/pages/tools/QRGenerator").then((module) => ({
    default: module.default,
    __esModule: true,
  }))
);
const UnitConverter = lazy(() => import("@/pages/tools/UnitConverter"));
const BarcodeGenerator = lazy(() => import("@/pages/tools/BarcodeGenerator"));
const JsonFormatter = lazy(() => import("@/pages/tools/JsonFormatter"));

// Loading fallback component with reduced height for faster initial paint
const LoadingFallback = () => (
  <div className="w-full h-[50vh] flex items-center justify-center">
    <div className="space-y-4 w-[80%] max-w-[600px]">
      <Skeleton className="h-8 w-full" />
      <Skeleton className="h-24 w-full" />
      <Skeleton className="h-8 w-2/3" />
    </div>
  </div>
);

const Routes = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <RouterRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tools" element={<AllTools />} />
        <Route path="/tools/color-picker" element={<ColorPicker />} />
        <Route
          path="/tools/lorem-ipsum-generator"
          element={<LoremIpsumGenerator />}
        />
        <Route
          path="/tools/password-generator"
          element={<PasswordGenerator />}
        />
        <Route path="/tools/qr-generator" element={<QRGenerator />} />
        <Route path="/tools/unit-converter" element={<UnitConverter />} />
        <Route path="/tools/barcode-generator" element={<BarcodeGenerator />} />
        <Route path="/tools/json-formatter" element={<JsonFormatter />} />
      </RouterRoutes>
    </Suspense>
  );
};

export default Routes;
