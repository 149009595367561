import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@/components/ThemeProvider";
import { Toaster } from "@/components/ui/toaster";
import { HelmetProvider } from 'react-helmet-async';
import Routes from "./Routes";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <Routes />
          <Toaster />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;